@import '../../../styles/vars.scss';

.wrapper {
  background: $white;
  box-shadow: 0px -0.625rem 2.75rem rgba(175, 112, 77, 0.15);
  color: $secondary;
  padding: 1rem 0;
  position: fixed;
  bottom: -50vh;
  left: 0;
  right: 0;
  z-index: 4;
  transition: bottom $ease-in-slow;
  color: $primary;

  &.active {
    bottom: 0;
  }
}

.text {
  font-size: $f16;
  line-height: 1.5;
  max-width: 66.25rem;
  margin: 0;
}

.button {
  margin-top: 0.5rem;
  padding: 0;
  background: transparent;
  border: none;
  color: $brown;
  font-size: $f16;
  line-height: 1.5;
  cursor: pointer;
  transition: color $ease-in;

  &:hover {
    color: $brownDark;
  }

  &:active,
  &:focus {
    outline: none;
  }
}

.close {
  position: absolute;
  top: 1.125rem;
  right: 1.125rem;
  width: 1.25rem;
  height: 1.25rem;
  padding: 0;
  background-color: transparent;
  border: none;
  cursor: pointer;

  svg {
    width: 100%;
    height: 100%;
  }
}

.showButton {
  margin-left: 0.75rem;
  padding: 0;
  background-color: transparent;
  border: none;
  cursor: pointer;
  font-size: $f16;
  color: $brown;
  transition: color $ease-in;

  &:hover {
    color: $brownDark;
  }

  &:active,
  &:focus {
    outline: none;
  }
}

@media (max-width: $w992) {
  .wrapper {
    padding: 0.75rem 0;
  }

  .container {
    padding: 0 1rem;

    &--show {
      padding: 0.125rem 1rem;
    }
  }

  .text {
    max-width: calc(100% - 1.25rem);
    font-size: $f14;
  }

  .button {
    font-size: $f14;
  }

  .showButton {
    font-size: $f14;
  }

  .close {
    top: 1rem;
    right: 1rem;
    width: 1rem;
    height: 1rem;
  }
}
