@import 'styles/vars';

.link {
  position: relative;
  padding: 0 $marg16;
  line-height: 1;
  background: transparent;
  border: none;
  cursor: pointer;
  color: $primary;

  &:last-child {
    padding-right: 0;
  }

  &:nth-child(2) {
    @media (max-width: $w1080) {
      position: absolute;
      top: 50%;
      left: 1.25rem;
      transform: translateY(-50%);
    }
  }

  svg {
    pointer-events: none;

    &.active path {
      fill: $white;
    }

    path {
      transition: fill $ease-in;
    }
  }

  &.black {
    svg path {
      fill: $white;
    }
  }

  &:hover {
    svg path {
      fill: $brown;
    }
  }
}

.search {
  cursor: pointer;
  @media (max-width: $w992) {
    display: none;
  }
}

.total {
  position: absolute;
  left: 0.75rem;
  top: 0;
  display: block;
  color: $white;
  background: $brown;
  border-radius: 50%;
  min-width: 1rem;
  height: 1rem;
  padding: 0 0.25rem;
  line-height: 1rem;
  text-align: center;
  font-size: 0.625rem;
}
